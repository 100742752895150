<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-30 radius-20"
		>
			<div>
				<div class="under-line pb-10 flex-row">
					<h3 class="flex-1">{{ program.title }}</h3>
				</div>
			</div>

			<div class="mt-30">

				<Mafai1114
					v-if="is_1114"
					:user="user"

					@click="toMypage"
				></Mafai1114>

				<PopupLayer
					v-else
				>
					<template
						v-slot:body
					>
						<div class="width-320 bg-white ma-auto radius-20">

							<div class="pa-20 flex-row justify-space-between under-line">
								<h4>Google OTP</h4>
								<v-icon @click="$emit('goBack')">mdi-close-circle</v-icon>
							</div>

							<div class="pa-20">
								<p class="text">사용자 확인을 위해 소셜 인증을 완료해야 합니다.<br>
									인증 후 Google OTP를 {{ text_reg }} 합니다.</p>
							</div>

							<div class="pa-20">
								<button
									class="btn btn-kakao"
									@click="loginWith('kakao')"
								>kakao 로그인</button>
								<button
									class="btn btn-google mt-10" @click="loginWith('google')"
								>Google 로그인</button>
								<button
									class="btn btn-apple mt-10" @click="loginWith('apple')"
								>Apple 로그인</button>

								<vue-apple-login
									v-show="false"

									:onSuccess="handleAppleLoginSuccess"
									:onFailure="handleAppleLoginFail"
								></vue-apple-login>
							</div>
						</div>
					</template>
				</PopupLayer>
			</div>
		</div>
	</div>
</template>

<script>

	import Mafai1114 from '@/view/Mypage/mafia111-4'
	import MypageSide from "@/view/Mypage/mypage-lnb";
	import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'mafia1113'
	, props: ['user']
	, components: {PopupLayer, MypageSide, Mafai1114 }
	, data: function(){
		return {
			program: {
				name: 'Google OTP 재등록'
				, title: 'Goggle OTP 재등록'
				, not_header: true
				, not_footer: true
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, social_code: ''
			, is_1114: false
			, is_pin: false
			,pin_option: {
				pin_type: 'check'
			}
		}
	}

	, computed: {
		text_reg: function(){
			let t = '등록'
			if(this.user.gogle_otp_reg_fg == 'Y'){
				t = '재등록'
			}
			return t
		}
	}
	, methods: {
		loginWith: async function(type){
			console.log('loginWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					await this.handleSignInKakao()
				}catch(e){
					console.log('kakao login error', e)
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					await this.handleClickUpdateScope2()
				}catch(e){
					console.log('google login error', e)
				}
			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					document.getElementById('appleid-signin').click()
				}catch(e){
					console.log('google login error', e)
				}
			}
		}

		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}

			let t = false
			if(t){
				let REST_API_KEY = process.env.VUE_APP_KEY_KAKAO_REST
				let REDIRECT_URI = this.kakao_return_url
				window.document.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=1234`
			}else{
				console.log(window.Kakao.isInitialized());
				console.log('kakao token: ' + window.Kakao.Auth.getAccessToken())
				await this.kakaoLogin()
			}
		}


		,kakaoLogin: async function(){
			let self = this
			window.Kakao.Auth.loginForm({
				success: function(authObj) {
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())

					self.loginWithSocial(authObj)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}

		, loginWithSocial: async function(e){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_confirm_social
					, data: {
						member_number: this.user.member_number
						, member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					console.log('this.token_info', this.token_info)

					let certfc = this.$encodeStorage.getSessionCertfc()

					if(!certfc){
						throw 'not certfc'
					}else{
						await this.$encodeStorage.setSessionToken(result.data.session_token)
						this.$bus.$emit('onPin', 'check')
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,pinClick: async function(){
			this.is_pin = false
			this.is_1114 = true
		}
		,pinCancel: function(){
			console.log('cancel')
			this.is_pin = false
		}
		, async handleClickUpdateScope2() {
			console.log('handleClickUpdateScope2')

			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();
			try {
				let result = await googleUser.grant(option);
				console.log("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					console.log("t re!!", t);
				}

				let r = {
					type : 'google'
					, access_token: t.access_token
				}

				await this.loginWithSocial(r);

				//await this.joinWithGoogle(googleUser.Cc.access_token, googleUser.Av.mw)
			} catch (error) {
				console.error(error);
			}
		}
		,toMypage: function(){
			this.is_pin = false
			this.is_1114 = false
			this.$bus.$emit('getUserInfo')
			this.$bus.$emit('to', { name: 'myPage'})
		}
		, handleAppleLoginSuccess: function(data){
			let r = {
				type : 'apple'
				, access_token: data.authorization.code
			}

			this.loginWithSocial(r);
		}
		, handleAppleLoginFail: function(error){
			console.log(error)
			this.$bus.$emit('notify', { type: 'error', message: '애플 로그인 실패'})
		}

	}
	, created() {
		this.$bus.$emit('onLoad', this.program)

		window.sign_in_social = (e) => {
			try{
				this.loginWithSocial(e)
			}catch (e) {
				console.log(e)
			}
		}

		this.$bus.$on('pinCallback', (pin_type) => {
			switch (pin_type){
				default:
				case 'check':
					this.$bus.$emit('offPin')
					this.is_1114 = true
					break
			}
		})
	}
}
</script>